export const environment = {
  production: true,
  site: 'tjl',
  translationKey: 'mintjl',
  clientDetails: {
    name: 'Tjenestemændenes Låneforening',
    address1: 'Gammel Kongevej 60, 10. sal',
    zipCode: '1850',
    city: 'Frederiksberg C.',
    country: 'Danmark',
    phone: '3312 3228',
  },
  formSubmitEmail: 'tjl@tjlaan.dk',
  logoPath: '/assets/images/tjl-logotopbar.png',
  logoFooterPath: '/assets/images/tjl-logofooter.png',
  homepage: 'https://www.tjlaan.dk',
  emailTemplateConfig: {
    logoLink: 'https://www.tjlaan.dk/Files/Images/TJLAAN%202020/Images/Graphics/tjlaan_logo.png',
    conditionLink: 'https://www.tjlaan.dk/ansoeg-om-laan/laanebetingelser',
    primaryColor: '#006676'
  },
  sentryDSN: 'https://3b020bc3c66c4419a7ab8851167ef19f@o263138.ingest.sentry.io/4504168665382912'
};
